import React, { useState } from 'react';
import { Grid, GridItem, GridRow } from '../../components/Grid';
import { ImagesService } from 'service/images/imagesService';

import NextImage from 'components/NextImage';
import { useRouter } from 'next/router';
import {
  BackgroundWrapper,
  Container,
  LeftAsset,
  ProductTitle,
  RightAsset,
  SearchContainer,
  SuperText,
  TextWrapper,
} from './styles';
const CustomSearch = dynamic(() => import('components/Instantsearch'), {
  ssr: false,
});

import dynamic from 'next/dynamic';
import type { GlobalSearch as TGlobalSearch } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps, UseExtendedImage } from 'templates/TemplateMapper/types';
import { SearchService } from 'service/search/searchService';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';

interface IGlobalSearch extends ISharedTemplateProps {
  templateProps: UseExtendedImage<TGlobalSearch, ['backgroundAsset', 'backgroundAssetMobile']>;
}

const metaballs = (rightAsset: any, leftAsset: any) => (
  <>
    <RightAsset data-test="right-picture">
      <NextImage sources={ImagesService.getPictureSources(rightAsset)} alt="" />
    </RightAsset>

    <LeftAsset data-test="left-picture">
      <NextImage sources={ImagesService.getPictureSources(leftAsset)} alt="" />
    </LeftAsset>
  </>
);

const searchBoxRef = React.createRef<HTMLInputElement>();

export const GlobalSearch = ({ templateProps, searchData }: IGlobalSearch) => {
  const { superText, rightAsset, leftAsset, backgroundAsset, backgroundAssetMobile, title } =
    templateProps;
  const { pathTranslations } = useGlobalContext();
  const [hitsNumber, setHitsNumber] = useState<number>(-1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const id = String(Math.floor(Math.random() * (10000 - 0)));
  const componentName = `${id}-global-search`;
  const router = useRouter();
  const submitHandler = (searchValue: string) => {
    SearchService.searchSubmitHandler(searchValue, pathTranslations.search, router);
  };

  const handleSubmit = () => {};
  return (
    <Container data-test={componentName}>
      <Grid skipPaddingOnMobile>
        <GridRow columns={16}>
          <GridItem colSpan={12} colStart={3} className="md:relative">
            {metaballs(rightAsset, leftAsset)}
            <div className="h-fit relative">
              <div className="hidden md:block">
                <BackgroundWrapper>
                  <NextImage
                    className="h-full w-full object-contain"
                    src={backgroundAsset?.asset?.[0]?.background ?? ''}
                    alt="Global search background"
                  />
                </BackgroundWrapper>
              </div>
              <div className="block md:hidden">
                <BackgroundWrapper>
                  <NextImage
                    className="h-full w-full object-contain"
                    src={backgroundAssetMobile?.asset?.[0]?.background ?? ''}
                    alt="Global search background"
                  />
                </BackgroundWrapper>
              </div>
              <TextWrapper>
                {superText && (
                  <SuperText tag="p" type="lead" data-test="global-search-super-text">
                    {superText}
                  </SuperText>
                )}
                {title && (
                  <ProductTitle tag="h3" type="sm" data-test="global-search-title">
                    {title}
                  </ProductTitle>
                )}
                <SearchContainer>
                  {searchData?.searchClient && (
                    <CustomSearch
                      searchData={searchData}
                      handleSubmit={handleSubmit}
                      setHitsNumber={setHitsNumber}
                      hitsNumber={hitsNumber}
                      setSearchValue={setSearchValue}
                      searchValue={searchValue}
                      setShow={setShow}
                      show={show}
                      searchSubmitHandler={submitHandler}
                      ref={searchBoxRef}
                      autoFocus={false}
                    />
                  )}
                </SearchContainer>
              </TextWrapper>
            </div>
          </GridItem>
        </GridRow>
      </Grid>
    </Container>
  );
};
