import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { Title, Text as Paragraph } from '../../components/Typography';

export const Container = tw.div`
  relative
  md:pt-20
  pb-20
  min-h-760
`;
Container.displayName = 'Container';

export const BackgroundWrapper = tw.div`
  absolute
  w-full
  min-h-528
`;
BackgroundWrapper.displayName = 'BackgroundWrapper';

export const TextWrapper = tw.div`
  text-center
  md:pt-40
  px-4
  md:px-28
  lg:px-32
  md:mb-20
  sm:mb-10
  min-h-528
  md:min-h-354
  flex
  flex-col
  align-center
  justify-center
`;
TextWrapper.displayName = 'TextWrapper';

export const RightAsset = styled.div`
  position: absolute;
  top: 350px;
  right: 0;
  z-index: 1;
  width: 40%;
  max-width: 286px;
  max-height: 286px;
  @media screen and (min-width: 768px) {
    right: -50px;
    top: 310px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
RightAsset.displayName = 'RightAsset';

export const LeftAsset = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 40%;
  max-width: 286px;
  max-height: 286px;
  @media screen and (min-width: 768px) {
    top: 185px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
LeftAsset.displayName = 'LeftAsset';

export const SuperText = tw(Paragraph)`
  mb-2
  text-darkgray
  opacity-70
  pt-0.5
`;
SuperText.displayName = 'SuperText';

export const ProductTitle = tw(Title)`
  mb-2.5
  relative
`;
ProductTitle.displayName = 'ProductTitle';

export const SearchContainer = styled.div`
  position: relative;
  z-index: 22;
  .ais-Hits {
    margin-top: 50px;
  }
  .ais-SearchBox {
    position: relative;
  }
  .ais-SearchBox-input {
    width: 100%;
    border-radius: 50px;
    height: 90px;
    margin-top: 30px;
    padding: 0 60px;
    font-size: 1.75rem;
    border-color: #bbc4ba;
  }
  .ais-SearchBox-reset {
    display: none;
  }
  .ais-SearchBox-submit {
    position: absolute;
    top: 64px;
    right: 40px;
    padding: 5px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .ais-SearchBox-loadingIndicator {
    position: absolute;
    right: 80px;
    top: 64px;
  }
  input.ais-SearchBox-input[type='search']::-webkit-search-decoration,
  input.ais-SearchBox-input[type='search']::-webkit-search-cancel-button,
  input.ais-SearchBox-input[type='search']::-webkit-search-results-button,
  input.ais-SearchBox-input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
  .ais-Hits-list {
    padding: 0;
    margin: 0;
  }
  .ais-SearchResults {
    background: #fff;
    border: 1px solid #bbc4ba;
    border-radius: 45px;
    padding: 50px 0 0;
    position: absolute;
    z-index: 999;
    top: 130px;
    width: 100%;
    text-align: left;
  }
  @media (max-width: 480px) {
    .ais-SearchBox-input {
      height: 60px;
      padding: 0 88px 0 20px;
      font-size: 1rem;
    }
    .ais-SearchResults {
      padding: 20px;
      top: 100px;
    }
    .ais-SearchBox-submit {
      top: 43px;
      right: 20px;
      z-index: 20;
    }
    .ais-SearchBox-loadingIndicator {
      top: 52px;
      right: 60px;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
`;
